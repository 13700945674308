import styles from "./style.module.scss";
interface Props {
    title: string;
    list: string[];
    isOpen: boolean;
}

const AccordionItem: React.FC<Props> = ({ title, list, isOpen }) => {
    return (
        <div className={`flex flex-col ${styles["border-bottom"]}`}>
            <div className="flex p-2 cursor-pointer items-center">
                <span
                    className={`rounded-md w-8 h-8 flex items-center justify-center mr-4 text-[30px]`}
                >
                    <div
                        className={`${styles.plus} ${
                            isOpen ? styles.minus : ""
                        }`}
                    ></div>
                </span>
                <p className="text-base text-left text-[16px] md:text-[20px]">
                    {title}
                </p>
            </div>
            <div
                className={`${styles.answer} ${
                    !isOpen
                        ? "h-0 overflow-hidden"
                        : "p-3 ml-3  min-h-3.5 overflow-auto"
                } text-sm text-gray-400 text-justify`}
            >
                {list.map((point, index) => (
                    <p key={index}>{point}</p>
                ))}
            </div>
        </div>
    );
};
export default AccordionItem;
