import { ReactElement, useState } from "react";

import FaqImage from "../icons/faqImage";
import AccordionItem from "./accordionItem";

const questions = [
    {
        title: "What Is Debt Relief? ",
        list: [
            "Debt relief refers to various strategies and programs aimed at helping individuals and organizations reduce or eliminate their outstanding debts. This can include options such as debt consolidation, debt settlement, bankruptcy, and services like debt counseling and management plans.",
            "Debt relief programs can be helpful for those who are struggling to manage their debts due to financial challenges such as job loss or high-interest rates and can provide a pathway to financial stability and freedom.",
        ],
    },
    {
        title: "How Can Debt Relief Help You?",
        list: [
            "Debt relief can help individuals struggling to manage their debts in several ways. Depending on the program or strategy chosen, debt relief can provide a path to reducing or eliminating outstanding debts, reducing interest rates and fees, and consolidating multiple debts into a single monthly payment. ",
            "Debt relief can also relieve the stress and anxiety associated with financial difficulties, allowing individuals to focus on rebuilding their financial stability and future. ",
        ],
    },
    {
        title: "How Will This Impact Your Credit Score? ",
        list: [
            "The impact of debt relief on your credit score will depend on the type of debt relief program you choose and your financial situation. Debt settlement and bankruptcy, for example, may harm your credit score, as they involve negotiating with creditors or discharging debts entirely. Debt consolidation, on the other hand, may have a neutral or positive impact on your credit score if you can make timely payments on the new loan.",
            "It's essential to carefully consider the potential impact on your credit score before choosing a debt relief program and to work with a reputable company or professional to help you navigate the process and minimize any adverse effects. With responsible financial management and a commitment to rebuilding your credit, you can work to improve your credit score over time, even after pursuing debt relief.",
        ],
    },
    {
        title: "What Is Included in the Free Evaluation?",
        list: [
            "One of our companies' expert Debt Relief advisors will contact you. They will discuss your debt, finances, and options. They will also discuss a general strategy for improving your credit score.",
        ],
    },
];

export default function FAQ(): ReactElement {
    const [activeTab, setActiveTab] = useState(0);

    const clickHandler = (idx: number) => {
        if (idx === activeTab) {
            setActiveTab(-1);
            return;
        }
        setActiveTab(idx);
    };
    return (
        <div
            className={`flex  justify-between items-center flex-col lg:flex-row max-w-7xl mx-auto  px-4vw lg:px-4  lg:mb-20`}
        >
            <div className={` max-w-[750px]`}>
                <h2
                    className={`font-bold capitalize mb-12 text-3xl text-center md:text-left md:text-4xl `}
                >
                    frequently asked questions (FAQs)
                </h2>
                {questions?.map(
                    (item: { title: string; list: string[] }, idx: number) => {
                        return (
                            <div key={idx} onClick={() => clickHandler(idx)}>
                                <AccordionItem
                                    title={item.title}
                                    list={item.list}
                                    isOpen={idx === activeTab}
                                />
                            </div>
                        );
                    },
                )}
            </div>
            <div
                className={`relative lg:w-[365px] lg:min-w-[365px] lg:h-[524px] w-[165px] h-[324px]`}
            >
                <FaqImage />
            </div>
        </div>
    );
}
