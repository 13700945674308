import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import HowThisWorkImageIcon from "../icons/howThisWorkImage";
const steps = [
    {
        id: 1,
        text: "After you fill out the form above, you will be connected with one of our best debt repair companies.",
    },
    {
        id: 2,
        text: "Once you are connected with the company, a representative will call you to ask you some questions about your debt situation, finances, and history.",
    },
    {
        id: 3,
        text: "After that, the company will recommend debt relief options.  In addition to this, the company will also recommend strategies for preventing debt in the future. ",
    },
    {
        id: 4,
        text: "Congratulations! You are on your way to being debt free and financially secure!  ",
    },
];
export default function HowThisWork(): ReactElement {
    return (
        <div
            className={`flex ${styles["howThisWork"]} flex-col md:flex-row max-w-7xl mx-auto  px-4vw lg:px-4`}
        >
            <div className={`${styles["howThisWorkListWrapper"]}`}>
                <h2
                    className={`font-bold capitalize mb-12 text-2xl text-center md:text-left md:text-4xl `}
                >
                    How{" "}
                    <span className="text-[#ff8a00]">
                        DebtReliefAnalysis.com
                    </span>{" "}
                    Works
                </h2>
                <div>
                    {steps.map((step) => (
                        <div
                            className={`flex justify-start items-center mb-12`}
                            key={step.id}
                        >
                            <div
                                className={`flex justify-center items-center font-bold ${styles["stepNum"]}`}
                            >
                                {step.id}
                            </div>
                            <div
                                className={`${styles["stepText"]} text-base md:text-xl`}
                            >
                                {step.text}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={` ${styles["howThisWorkImage"]}`}>
                <HowThisWorkImageIcon />
            </div>
        </div>
    );
}
